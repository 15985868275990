import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SpeakToUs from '../components/SpeakToUs';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Brands from '../components/Brands';

// eslint-disable-next-line
export const ServicesTemplate = ({ hero, servicesSection, brands, speakToUs, id }) => {
	return (
		<div style={{ overflowX: 'hidden' }}>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
				image={`${id}.jpg`}
			/>
			<Hero {...hero} />
			<Services {...servicesSection} />
			<Brands
				brandsTitleOne={brands.brandsTitleOne}
				brandsTitleTwo={brands.brandsTitleTwo}
				brandsTitleThree={brands.brandsTitleThree}
				logos={[
					brands.logo1,
					brands.logo2,
					brands.logo3,
					brands.logo4,
					brands.logo5,
					brands.logo6,
					brands.logo7,
					brands.logo8,
					brands.logo9,
					brands.logo10,
					brands.logo11,
					brands.logo12,
					brands.logo13,
					brands.logo14
				]}
			/>
			<SpeakToUs {...speakToUs} />
		</div>
	);
};

ServicesTemplate.propTypes = {
	title: PropTypes.string,
	helmet: PropTypes.object
};

const ServicesPage = ({ data }) => {
	const { markdownRemark: services } = data;

	return (
		<Layout>
			<ServicesTemplate
				id={services.id}
				html={services.html}
				frontmatter={services.frontmatter}
				hero={services.frontmatter.hero}
				servicesSection={services.frontmatter.servicesSection}
				brands={services.frontmatter.brands}
				speakToUs={services.frontmatter.speakToUs}
			/>
		</Layout>
	);
};

ServicesPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object
	})
};

export default ServicesPage;

export const pageQuery = graphql`
	query Services {
		markdownRemark(frontmatter: { templateKey: { eq: "services" } }) {
			id
			frontmatter {
				heading
				subheading
				color
				hero {
					showLogo
					heroColor
					lineOne
					lineOneColor
					lineOneFill
					lineTwo
					lineTwoColor
					lineTwoFill
					inline
				}
				servicesSection {
					servicesTitle
					servicesParagraph
					serviceOne {
						serviceOneImage
						serviceOneTitle
						serviceOneItems
					}
					serviceTwo {
						serviceTwoImage
						serviceTwoTitle
						serviceTwoItems
					}
					serviceThree {
						serviceThreeImage
						serviceThreeTitle
						serviceThreeItems
					}
					serviceFour {
						serviceFourImage
						serviceFourTitle
						serviceFourItems
					}
				}
				brands {
					brandsTitleOne
					brandsTitleTwo
					brandsTitleThree
					logo1
					logo2
					logo3
					logo4
					logo5
					logo6
					logo7
					logo8
					logo9
					logo10
					logo11
					logo12
					logo13
					logo14
				}
				speakToUs {
					title
					paragraph
					button
				}
				footer {
					email
					phone
					address
					lineOne
					lineTwo
					linkOne
					linkTwo
					copyright
				}
			}
		}
	}
`;
