import { css as styled } from '@emotion/react';

const styling = theme => styled`

    background-color: #1F212D;
    width: 100vw;

    .wrapper {
        background-color: #1F212D;
        padding: ${theme.spacing(12, 6)};

        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(6, 0)};
        }
    }

    .title {
        font-size: 2rem;
        font-weight: 600 !important;
        max-width: 70%;
        line-height: 2.2rem;
        text-align: center;
        margin-top: ${theme.spacing(3)};
        color: #fff;

        ${theme.breakpoints.up('xl')} {
            font-size: 2.8rem;
            line-height: 3.5rem;
            max-width: 100%;
        }

        ${theme.breakpoints.down('sm')} {
            max-width: 90%;
        }
        
        span {
            color: #EB5558;
        }
    }

    .brands-container {
        padding: ${theme.spacing(4, 0)};
        margin: auto;
    }

    .brands-item {
        background-color: #454650;
        min-height: 134px;
        padding: ${theme.spacing(2)};
        display: flex;
        align-items: center;
        border-radius: 3px;
        margin: ${theme.spacing(0.8)};
    }

`;

export default styling;
