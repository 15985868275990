import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import serviceImage_01 from '../../../static/SVG/SVG/services-icon-01.svg';
import serviceImage_02 from '../../../static/SVG/SVG/services-icon-02.svg';
import serviceImage_03 from '../../../static/SVG/SVG/services-icon-03.svg';
import serviceImage_04 from '../../../static/SVG/SVG/services-icon-04.svg';

const Services = ({
	servicesTitle,
	servicesParagraph,
	serviceOne,
	serviceTwo,
	serviceThree,
	serviceFour
}) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box id="hero-lower" css={styles(theme)}>
			<Container>
				<Grid
					className="container"
					container
					direction={mobile ? 'column' : 'row'}
					nowrap
					p={mobile ? 5 : 10}
				>
					<Grid className="container-item" item container direction="column" py={4}>
						<Typography className="item-title" component={'h1'} pb={5}>
							{servicesTitle}
						</Typography>
						<Typography className="item-paragraph" component={'p'}>
							{servicesParagraph}
						</Typography>
					</Grid>
					<Grid className="container-services" item container columnSpacing={4}>
						<Grid item className="item-service" item container>
							<Grid className="service-top" sx={{ mb: 2 }} item container direction="row" alignItems="center" wrap="nowrap">
								<img src={serviceImage_01} width="50" alt="" />
								<Typography className="service-name" component={'h2'}>
									{serviceOne?.serviceOneTitle}
								</Typography>
							</Grid>
							<Grid className="service-items" item container>
								{serviceOne?.serviceOneItems.map(item => {
									return <Typography>{item}</Typography>;
								})}
							</Grid>
						</Grid>
						<Grid className="item-service" item container>
							<Grid className="service-top" sx={{ mb: 2 }} item container wrap="nowrap">
								<img src={serviceImage_02} width="50" alt="" />
								<Typography className="service-name" component={'h2'}>
									{serviceTwo?.serviceTwoTitle}
								</Typography>
							</Grid>
							<Grid className="service-items" item container>
								{serviceTwo?.serviceTwoItems.map(item => {
									return <Typography>{item}</Typography>;
								})}
							</Grid>
						</Grid>
						<Grid className="item-service" item container>
							<Grid className="service-top" sx={{ mb: 2 }} item container wrap="nowrap">
								<img src={serviceImage_03} width="50" alt="" />
								<Typography className="service-name" component={'h2'}>
									{serviceThree?.serviceThreeTitle}
								</Typography>
							</Grid>
							<Grid className="service-items" item container>
								{serviceThree?.serviceThreeItems.map(item => {
									return <Typography>{item}</Typography>;
								})}
							</Grid>
						</Grid>
						<Grid className="item-service" item container>
							<Grid className="service-top" sx={{ mb: 2 }} item container wrap="nowrap">
								<img src={serviceImage_04} width="50" alt="" />
								<Typography className="service-name" component={'h2'}>
									{serviceFour?.serviceFourTitle}
								</Typography>
							</Grid>
							<Grid className="service-items" item container>
								{serviceFour?.serviceFourItems.map(item => {
									return <Typography>{item}</Typography>;
								})}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Services;
