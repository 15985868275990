import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
import Image from '../Image';

import logo1 from '../../../static/img/brand-logos/asset_01.svg';
import logo2 from '../../../static/img/brand-logos/asset_02.svg';
import logo3 from '../../../static/img/brand-logos/asset_03.svg';
import logo4 from '../../../static/img/brand-logos/asset_04.svg';
import logo5 from '../../../static/img/brand-logos/asset_05.svg';
import logo6 from '../../../static/img/brand-logos/asset_06.svg';
import logo7 from '../../../static/img/brand-logos/asset_07.svg';
import logo8 from '../../../static/img/brand-logos/asset_08.svg';
import logo9 from '../../../static/img/brand-logos/asset_09.svg';
import logo10 from '../../../static/img/brand-logos/asset_10.svg';
import logo11 from '../../../static/img/brand-logos/asset_11.svg';
import logo12 from '../../../static/img/brand-logos/asset_12.svg';
import logo13 from '../../../static/img/brand-logos/asset_13.svg';
import logo14 from '../../../static/img/brand-logos/asset_14.svg';

const Brands = ({
	brandsTitleOne,
	brandsTitleTwo,
	brandsTitleThree,
	logos
}) => {
	const theme = useTheme();

	return (
		<Box css={styles(theme)}>
			<Container>
				<Grid className="wrapper" container direction="row">
					<Grid className="wrapper-text" mb={6} item container justifyContent="center">
						<Typography className="title">
							{brandsTitleOne} <span>{brandsTitleTwo}</span> {brandsTitleThree}
						</Typography>
					</Grid>
					<Grid className="brands-container" item container justifyContent="center">
						{/* {Object.values(logos).forEach(item => {
							<Grid className="brands-item" item>
								<img src={item} alt='Brand' />
							</Grid>
						})} */}

						{/* {logos?.map(logo => {
							return (
								<Grid className="brands-item" item>
									<img src={logo?.childImageSharp?.gatsbyImageData} alt='Brand' />
								</Grid>
							)
						})} */}

						<Grid className="brands-item" item>
							<img src={logo1} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo2} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo3} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo4} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo5} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo6} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo7} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo8} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo9} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo10} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo11} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo12} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo13} alt="Brand" width="105" />
						</Grid>
						<Grid className="brands-item" item>
							<img src={logo14} alt="Brand" width="105" />
						</Grid>

						{/* // 	<Grid className="brands-item" item>
					// 		<img src={logos?.logoOne} alt="Brand" width="105" />
					// 	</Grid>
					// 	<Grid className="brands-item" item>
					// 		<img src={logos?.logoTwo} alt="Brand" width="105" />
					// 	</Grid>
					// 	<Grid className="brands-item" item>
					// 		<img src={logos?.logoThree} alt="Brand" width="105" />
					// 	</Grid>
					// 	<Grid className="brands-item" item>
					// 		<img src={logos?.logoFour} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoFive} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoSix} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoSeven} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoEight} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoNine} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoTen} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoEleven} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoTwelve} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoThirteen} alt="Brand" width="105" />
					// 	</Grid>
                    //     <Grid className="brands-item" item>
					// 		<img src={logos?.logoFourteen} alt="Brand" width="105" />
					// 	</Grid> */}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Brands;
