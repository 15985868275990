import {css as styled} from "@emotion/react"

const styling = (theme) => styled`
    .container {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 100vh;
        width: 100%;
        padding: ${theme.spacing(10, 5, 10, 10)};

        ${theme.breakpoints.up('xl')} {
            min-height: 40vh;
            padding: ${theme.spacing(5, 0)};
        }

        ${theme.breakpoints.down('lg')} {
            padding: ${theme.spacing(5, 3)};
            min-height: 70vh;
        }

        ${theme.breakpoints.down('md')} {
            padding: ${theme.spacing(5)};
        }

        ${theme.breakpoints.down('sm')} {
            padding: ${theme.spacing(2)};
        }
    }

    .container-item {
        flex-wrap: nowrap;
        max-width: 45%;
        padding-top: ${theme.spacing(14)};
        ${theme.breakpoints.down('md')} {
            padding-top: ${theme.spacing(4)};
            max-width: 100%;
        }
    }
    .item-title {
        max-width: 200px;
    }
    .item-paragraph {
        max-width: 350px;
        ${theme.breakpoints.down('md')} {
            max-width: 100%;
        }
    }

    .container-services {
        display: flex;
        width: 55%;
        padding-top: ${theme.spacing(3)};
        ${theme.breakpoints.down('md')} {
            width: 110%;
            padding: ${theme.spacing(0, 4)};
            margin: 0;
        }
        ${theme.breakpoints.down(520)} {
            flex-direction: column;
            padding: ${theme.spacing(0, 2)};
        }
    }
    .item-service {
        width: 50%;
        align-content: flex-start;
        margin-bottom: ${theme.spacing(6)};
        padding-right: ${theme.spacing(2)};
        padding-left: 0 !important;
        ${theme.breakpoints.down(520)} {
            padding: 0 !important;
            width: 100%;
        }
    }
    .service-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .service-items > * {
        margin-bottom: ${theme.spacing(1)};
        ${theme.breakpoints.down(520)} {
            width: 100%;
        }
    }

    .service-top {
        height: 75px;
    }

    h1 {
        color: #000;
        font-size: 3rem;
        line-height: 50px;

        ${theme.breakpoints.down('sm')} {
            font-size: 2rem;
        }
    }
    h2 {
        align-self: center;
        font-size: 1.8rem;
        padding-left: ${theme.spacing(2)};
        position: relative;
        top: 5px;
        text-wrap: nowrap;
    }
    p {
        color: #000;
        line-height: 1.8rem;
        font-weight: 800 !important;
        font-family: 'Comfortaa', sans-serif !important;
    }
`;

export default styling 